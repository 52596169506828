import React from 'react'
import {
  List,
  Datagrid,
  TextInput,
  Edit,
  SimpleForm,
  EditButton,
  Create,
  Filter,
  TextField,
  Show,
  required,
  minLength,
  SimpleShowLayout,
  ShowButton,
  Toolbar,
  SaveButton,
  DeleteButton,
  maxLength,
  minValue,
  maxValue,
  number,
  useRecordContext,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput
} from 'react-admin'

const validateName = [required(), minLength(1), maxLength(256)]
const validateDescription = [maxLength(2048)]
const validateAlias = [maxLength(64)]
const validatePermission = [minValue(0), maxValue(10), number()]
const validateOrder = [number()]

const TaskFilter = props => (
  <Filter {...props}>
    <TextInput source='name' alwaysOn />
    <TextInput source='alias' alwaysOn />
  </Filter>
)

const TaskTitle = () => {
  const record = useRecordContext()

  return <span>Task {record ? `"${record.name}"` : ''}</span>
}

const CustomToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
    <DeleteButton />
  </Toolbar>
)

export const TaskList = props => (
  <List bulkActionButtons={false} {...props} perPage={25} filters={<TaskFilter />}>
    <Datagrid>
      <TextField source='name' />
      <TextField source='alias' />
      <TextField source='order' />
      <TextField source='permission' />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
)

export const TaskShow = props => (
  <Show {...props} title={<TaskTitle />}>
    <SimpleShowLayout>
      <ReferenceField label='Parent' source='parentId' reference='tasks'>
        <TextField source='name' />
      </ReferenceField>
      <TextField source='name' />
      <TextField source='description' />
      <TextField source='alias' />
      <TextField source='order' />
      <TextField source='permission' />
    </SimpleShowLayout>
  </Show>
)

export const TaskEdit = props => (
  <Edit {...props} undoable={false} title={<TaskTitle />}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <ReferenceInput source='parentId' reference='tasks' sort={{ field: 'name', order: 'asc' }}>
        <AutocompleteInput optionText='name' resettable />
      </ReferenceInput>
      <TextInput fullWidth source='name' validate={validateName} />
      <TextInput fullWidth source='description' validate={validateDescription} />
      <TextInput fullWidth source='alias' validate={validateAlias} />
      <TextInput fullWidth source='order' validate={validateOrder} />
      <TextInput fullWidth source='permission' validate={validatePermission} />
    </SimpleForm>
  </Edit>
)

export const TaskCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput source='parentId' reference='tasks' sort={{ field: 'name', order: 'asc' }} enableGetChoices={({ q }) => q.length >= 2}>
        <AutocompleteInput optionText='name' />
      </ReferenceInput>
      <TextInput fullWidth source='name' validate={validateName} />
      <TextInput fullWidth source='description' validate={validateDescription} />
      <TextInput fullWidth source='alias' validate={validateAlias} />
      <TextInput fullWidth source='order' validate={validateOrder} />
      <TextInput fullWidth source='permission' validate={validatePermission} />
    </SimpleForm>
  </Create>
)
